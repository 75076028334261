import Image from 'next/image'
import clsx from 'clsx'
import { useState } from 'react'

import Link from '@/atoms/Link/Link'

/* utils */
import {
  PlatformDropdownProps,
  SolutionsDropdownProps,
  ResourceDropdownProps,
  TalentNetworkDropdownProps
} from './Header.utils'

/* assets */
import { ReactComponent as Arrow } from 'public/assets/cmp/navigation/icons/forward-arrow.svg'
import { ReactComponent as CogIcon } from 'public/assets/cmp/navigation/icons/platform-feat-cog-icon.svg'
import { ReactComponent as NewsletterIcon } from 'public/assets/cmp/navigation/icons/newsletter-icon.svg'

/* styles */
import styles from './desktop.module.css'

interface Platform {
  title: string
  description: string
  slug: string
  image: string
  colorOne: string
  colorTwo: string
  data: {
    title: string
    description: string
    titleIcon: string
    videoUrl: string
  }
}

export function PlatformDropdown({
  platforms,
  features
}: PlatformDropdownProps): JSX.Element {
  // State to track the hovered platform
  const [hoveredPlatform, setHoveredPlatform] = useState(platforms[0]) // Initialize with the first platform
  const [animate, setAnimate] = useState(false)

  // Function to handle hover
  const handleHover = (platform: Platform, isHovering: boolean) => {
    setHoveredPlatform(platform)
    setAnimate(isHovering)
  }
  return (
    <section className="bg-gradient-to-b rounded-b-xl shadow-2xl from-[#070425] to-[#090059] text-white | mx-auto">
      <div className="lg:flex">
        <div className="basis-2/3 | rounded-bl-xl">
          <div className="xl:max-w-col-12 ml-auto p-8 lg:py-7 lg:pl-10 lg:pr-5 xl:py-14 xl:pl-14 xl:pr-10">
            <div className="hidden lg:block">
              <div className="flex items-center gap-1 | text-sm text-yellow-40 font-semibold uppercase | mb-8">
                <div className="text-yellow-40">
                  <Image
                    src="/assets/cmp/navigation/icons/platform-title-flash-icon.svg"
                    alt="icon"
                    width={15}
                    height={15}
                    className="text-yellow-40"
                    priority={true}
                  />
                </div>
                Platform
              </div>
            </div>

            <div className="flex flex-wrap justify-center lg:justify-start lg:flex-nowrap gap-5 | mb-10">
              {platforms.map(platform => {
                const gradientStyle = {
                  background: `linear-gradient(to bottom, ${platform.colorOne}, ${platform.colorTwo})`
                }
                return (
                  <Link
                    key={platform.slug}
                    href={platform.slug}
                    target="_blank"
                    className="w-full"
                  >
                    <div
                      className="rounded-lg relative group overflow-hidden"
                      style={gradientStyle}
                      onMouseEnter={() => handleHover(platform, true)}
                      onMouseLeave={() => handleHover(platform, false)}
                    >
                      <div className="flex flex-col justify-between">
                        <div className="m-4 | h-[144px]">
                          <div className="flex justify-between | mb-4">
                            <div className="font-heading text-2xl lg:text-xl xl:text-2xl max-w-[200px] lg:max-w-[105px] xl:max-w-[158px]">
                              {platform.title}
                            </div>
                            <div>
                              <Image
                                src="/assets/cmp/navigation/icons/circular-right.svg"
                                alt=""
                                width={40}
                                height={40}
                                className="opacity-50 group-hover:opacity-100 group-hover:-rotate-45 transition-all ease-out"
                                priority={true}
                              />
                            </div>
                          </div>
                          <div className="text-sm xl:text-small max-w-[201px] lg:max-w-[162px]">
                            {platform.description}
                          </div>
                        </div>
                        <Image
                          src={platform.image}
                          alt=""
                          width={495}
                          height={357}
                          className="w-full mt-auto"
                          priority={true}
                        />
                      </div>
                      <div
                        className="absolute inset-0 bg-transparent border border-transparent transition-all rounded-lg"
                        style={{
                          borderColor:
                            hoveredPlatform === platform
                              ? platform.colorTwo
                              : 'transparent'
                        }}
                      ></div>
                    </div>
                  </Link>
                )
              })}
            </div>

            {/* Top Features Section */}
            <div>
              <p className="flex items-center gap-1 | text-sm text-white lg:text-yellow-40 font-semibold uppercase | border-[#ffffff66] border-b-[0.5px] border-spacing-4 pb-5 mb-5">
                <CogIcon className="fill-white lg:fill-yellow-40" />
                Top Features
              </p>
              <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
                {features.map((feature, index) => (
                  <Link
                    key={feature.slug}
                    href={feature.slug}
                    target="_blank"
                    className={clsx(
                      'flex items-center gap-2',
                      index !== features.length - 1 ? 'mb-5 lg:mb-0' : 'mb-0'
                    )}
                  >
                    <div className="text-sm hover:text-yellow-40">
                      {feature.title}
                    </div>
                    {feature.isNew ? (
                      <span className="text-[10px] text-[#B78EF0] font-semibold border border-[#B78EF0] py-1 px-2 rounded-3xl">
                        New
                      </span>
                    ) : null}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="basis-1/3 | rounded-br-xl bg-gradient-to-b from-[#0A0062] to-[#4016AB] | hidden lg:block">
          <div className="p-8 md:py-7 md:pl-8 xl:pt-14 xl:pl-16">
            {hoveredPlatform && (
              <div
                id={hoveredPlatform.data.title}
                className={clsx(
                  'font-medium | lg:max-w-xs',
                  animate ? styles.fadeIn : ''
                )}
              >
                <p className="font-heading flex items-center gap-1 | text-sm text-white font-semibold uppercase | border-[#ffffff66] border-b-[0.5px] border-spacing-4 pb-4 mb-10">
                  <Image
                    src={hoveredPlatform.data.titleIcon}
                    alt="icon"
                    width={15}
                    height={15}
                    priority={true}
                  />
                  {hoveredPlatform.data.title}
                </p>
                <div>
                  <video
                    src={hoveredPlatform.data.videoUrl}
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="justify-self-center | rounded-xl mb-9"
                  />
                  <div className="font-heading text-xl">
                    {hoveredPlatform.data.description}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export function SolutionsDropdown({
  industries,
  useCases
}: SolutionsDropdownProps): JSX.Element {
  return (
    <section className="bg-gradient-to-b from-[#070425] to-[#090059] text-white | mx-auto">
      <div className="lg:flex">
        <div className="basis-2/3 | rounded-bl-xl">
          <div className="xl:max-w-col-12 ml-auto p-8 lg:py-7 lg:pl-10 lg:pr-5 xl:py-14 xl:pl-14 xl:pr-10">
            <div className="hidden lg:block">
              <p className="flex items-center gap-1 | text-sm text-yellow-40 font-semibold uppercase | mb-8">
                <Image
                  src="/assets/cmp/navigation/icons/search-status.svg"
                  alt="icon"
                  width={15}
                  height={15}
                  priority={true}
                />
                Use Cases
              </p>
            </div>

            <div className="flex flex-wrap justify-center lg:justify-start lg:flex-nowrap gap-5">
              {useCases.map(useCase => {
                const gradientStyle = {
                  background: `linear-gradient(to bottom, ${useCase.colorOne}, ${useCase.colorTwo})`
                }
                const dynamicBorderColor: React.CSSProperties = {
                  '--dynamic-color': useCase.colorTwo
                } as any
                return (
                  <Link
                    key={useCase.slug}
                    href={useCase.slug}
                    target="_blank"
                    className={clsx(
                      'w-full border border-transparent rounded-lg overflow-hidden group',
                      styles.card
                    )}
                    style={dynamicBorderColor}
                  >
                    <div
                      className="relative overflow-hidden"
                      style={gradientStyle}
                    >
                      <div className="mx-5 mt-5 mb-6 lg:m-2 xl:m-5">
                        <div className="flex items-center justify-between | h-10 mb-4">
                          <div className="font-heading text-2xl lg:text-base">
                            {useCase.title}
                          </div>
                          <div>
                            <Image
                              src={useCase.icon}
                              alt=""
                              width={40}
                              height={40}
                              className="lg:w-8 lg:h-8 xl:w-10 xl:h-10 opacity-50 group-hover:opacity-100 group-hover:-rotate-45 transition-all ease-out"
                              priority={true}
                            />
                          </div>
                        </div>
                        <div className="text-sm xl:text-small max-w-[201px] lg:max-w-[152px]">
                          {useCase.description}
                        </div>
                      </div>
                      <Image
                        src={useCase.imageDesk}
                        alt=""
                        width={186}
                        height={158}
                        className="w-full mt-auto hidden lg:block"
                        priority={true}
                      />
                      <Image
                        src={useCase.imageMob}
                        alt=""
                        width={186}
                        height={158}
                        className="w-full mt-auto block lg:hidden"
                        priority={true}
                      />
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>

        <div className="basis-1/3 | bg-gradient-to-b from-[#0A0062] to-[#4016AB]">
          <div className="p-8 md:py-7 md:pl-8 xl:py-14 xl:pl-16">
            <div className="font-medium | lg:max-w-col-3">
              <p className="flex items-center gap-1 | text-sm text-white font-semibold uppercase | border-[#ffffff66] border-b-[0.5px] border-spacing-4 pb-4 mb-8">
                <Image
                  src="/assets/cmp/navigation/icons/3dcube-industries.svg"
                  alt="icon"
                  width={15}
                  height={15}
                  priority={true}
                />
                Industries
              </p>
              {industries.map((industry, index) => (
                <div
                  key={industry.slug}
                  className={clsx(
                    index !== industries.length - 1 ? 'mb-9' : 'mb-0'
                  )}
                >
                  <Link
                    href={industry.slug}
                    target="_blank"
                    className="block font-medium text-sm |  text-white focus:outline-none group"
                  >
                    <div className="flex items-center gap-3 group-hover:text-yellow-40">
                      <industry.icon className="group-hover:text-yellow-40" />
                      {industry.title}
                      <Arrow className="group-hover:text-yellow-40" />
                      {industry.isNew ? (
                        <span className="text-[10px] text-[#B78EF0] font-semibold border border-[#B78EF0] py-1 px-2 rounded-3xl">
                          New
                        </span>
                      ) : null}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export function ResourcesDropdown({
  library,
  resources
}: ResourceDropdownProps): JSX.Element {
  return (
    <section className="bg-gradient-to-b from-[#070425] to-[#090059] text-white | mx-auto">
      <div className="lg:flex">
        <div className="basis-2/3 | rounded-bl-xl">
          <div className="xl:max-w-col-12 ml-auto p-8 lg:py-7 lg:pl-10 lg:pr-5 xl:py-14 xl:pl-14 xl:pr-10">
            <div className="hidden lg:block">
              <div className="flex items-center gap-1 | text-sm text-yellow-40 font-semibold uppercase | mb-8">
                <div className="text-yellow-40">
                  <Image
                    src="/assets/cmp/navigation/icons/resources-folder-mon-icon.svg"
                    alt="icon"
                    width={15}
                    height={15}
                    className="text-yellow-40"
                    priority={true}
                  />
                </div>
                Resources
              </div>
            </div>

            <div className="flex flex-wrap justify-center lg:justify-start lg:flex-nowrap gap-5 mb-7">
              {library.map(item => {
                const gradientStyle = {
                  background: `linear-gradient(to bottom, ${item.colorOne}, ${item.colorTwo})`
                }
                const dynamicBorderColor: React.CSSProperties = {
                  '--dynamic-color': item.colorTwo
                } as any
                return (
                  <Link
                    key={item.slug}
                    href={item.slug}
                    target="_blank"
                    className={clsx(
                      'w-full border border-transparent rounded-lg overflow-hidden group',
                      styles.card
                    )}
                    style={dynamicBorderColor}
                  >
                    <div
                      className="relative overflow-hidden"
                      style={gradientStyle}
                    >
                      <div className="flex flex-col justify-between">
                        <div className="m-5 lg:m-4 xl:m-5 | h-[144px] md:h-[110px]">
                          <div className="flex justify-between | mb-4">
                            <div className="font-heading text-2xl xl:text-xl max-w-[200px] xl:max-w-[162px]">
                              {item.title}
                            </div>
                            <div>
                              <Image
                                src="/assets/cmp/navigation/icons/circular-right.svg"
                                alt=""
                                width={40}
                                height={40}
                                className="opacity-50 group-hover:opacity-100 group-hover:-rotate-45 transition-all ease-out"
                                priority={true}
                              />
                            </div>
                          </div>
                          <div className="text-sm xl:text-small max-w-[201px] lg:max-w-[152px]">
                            {item.description}
                          </div>
                        </div>
                        <Image
                          src={item.image}
                          alt=""
                          width={495}
                          height={353}
                          className="w-full mt-auto"
                          priority={true}
                        />
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>

            <div className="border-[#ffffff66] border-t-[0.5px] pt-7">
              <div className="block md:hidden">
                <div className="flex items-center gap-1 | text-sm text-yellow-40 font-semibold uppercase | mb-5">
                  <NewsletterIcon />
                  Newsletter
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-8 gap-5 md:gap-10">
                <div className="md:col-span-3">
                  <Link
                    href="https://peppercontentinc.substack.com/"
                    target="_blank"
                  >
                    <Image
                      src="/assets/cmp/navigation/images/newsletter.png"
                      alt=""
                      width={280}
                      height={160}
                      className="w-full rounded-lg"
                      priority={true}
                    />
                  </Link>
                </div>
                <div className="md:col-span-5">
                  <div className="hidden md:block">
                    <div className="flex items-center gap-1 | text-sm text-yellow-40 font-semibold uppercase | mb-2">
                      <NewsletterIcon />
                      Newsletter
                    </div>
                  </div>
                  <div className="text-white">
                    <div className="text-xl md:text-2xl font-semibold | mb-2">
                      Introducing Co-Creative
                    </div>
                    <p className="text-small md:text-sm | max-w-sm mb-5">
                      A newsletter for marketers and creators who believe the
                      future of content is human + AI.
                    </p>
                    <Link
                      href="https://peppercontentinc.substack.com/"
                      target="_blank"
                      className="font-semibold underline underline-offset-4"
                    >
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="basis-1/3 | bg-gradient-to-b from-[#0A0062] to-[#4016AB]">
          <div className="p-8 md:py-7 md:pl-8 xl:pb-11 xl:pt-16 xl:pl-16">
            <div className="font-medium | lg:max-w-[268px]">
              {resources.map((resource, index) => (
                <div
                  key={resource.slug}
                  className={clsx(
                    'border-[#ffffff66] border-b-[0.5px] border-spacing-4',
                    index !== resources.length - 1
                      ? 'mb-6 pb-6'
                      : 'border-none mb-0 pb-0'
                  )}
                >
                  <Link
                    href={resource.slug}
                    target="_blank"
                    className="block font-medium text-sm | text-white focus:outline-none group"
                  >
                    <div className="flex items-center gap-2 | mb-3">
                      <resource.icon className="group-hover:text-yellow-40" />
                      <div className="text-[15px] group-hover:text-yellow-40">
                        {resource.title}
                      </div>

                      <Arrow className="group-hover:text-yellow-40" />
                      {resource.isNew ? (
                        <span className="text-[10px] text-[#B78EF0] font-semibold border border-[#B78EF0] py-1 px-2 rounded-3xl">
                          New
                        </span>
                      ) : null}
                    </div>
                    <div className="text-small">{resource.description}</div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export function TalentNetworkDropdown({
  links,
  features
}: TalentNetworkDropdownProps): JSX.Element {
  const listOneGradientStyle = {
    background: `linear-gradient(to bottom, ${links[0].colorOne}, ${links[0].colorTwo})`
  }
  const listTwoGradientStyle = {
    background: `linear-gradient(to bottom, ${links[1].colorOne}, ${links[1].colorTwo})`
  }
  const listThreeGradientStyle = {
    background: `linear-gradient(to bottom, ${links[2].colorOne}, ${links[2].colorTwo})`
  }
  return (
    <section className="bg-gradient-to-b from-[#070425] to-[#090059] text-white | mx-auto">
      <div className="lg:flex">
        <div className="basis-2/3 | rounded-bl-xl">
          <div className="xl:max-w-col-12 ml-auto p-8 lg:py-7 lg:pl-10 lg:pr-5 xl:py-14 xl:pl-14 xl:pr-10">
            <div className="hidden lg:block">
              <p className="flex items-center gap-1 | text-sm text-yellow-40 font-semibold uppercase | mb-8">
                <Image
                  src="/assets/cmp/navigation/icons/tm-title-star-icon.svg"
                  alt="icon"
                  width={15}
                  height={15}
                  priority={true}
                />
                Talent Marketplace
              </p>
            </div>
            <div className="gallery | grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div className="lg:row-[1/3]">
                {links[0] ? (
                  <Link
                    key={links[0].slug}
                    href={links[0].slug}
                    target="_blank"
                    className="w-full"
                  >
                    <div
                      className="rounded-lg relative group overflow-hidden"
                      style={listOneGradientStyle}
                    >
                      <div className="p-4 xl:p-6">
                        <div className="flex justify-between | mb-5">
                          <div className="font-heading text-lg lg:text-2xl | lg:max-w-[264px]">
                            {links[0].title}
                          </div>
                          <Image
                            src={links[0].icon}
                            alt=""
                            width={40}
                            height={40}
                            className="opacity-50 group-hover:opacity-100 group-hover:-rotate-45 transition-all ease-out"
                            priority={true}
                          />
                        </div>
                        {links[0].description ? (
                          <div className="text-small">
                            {links[0].description}
                          </div>
                        ) : null}
                      </div>
                      <Image
                        src={links[0].image}
                        alt=""
                        width={377}
                        height={253}
                        className="w-full mt-auto"
                        priority={true}
                      />
                      <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#493AD5] transition-all rounded-lg"></div>
                    </div>
                  </Link>
                ) : null}
              </div>
              <div className="lg:row-[1/2]">
                {links[1] ? (
                  <Link
                    key={links[1].slug}
                    href={links[1].slug}
                    target="_blank"
                  >
                    <div
                      className="rounded-lg relative group overflow-hidden h-full"
                      style={listTwoGradientStyle}
                    >
                      <div className="m-4 xl:m-8">
                        <div className="flex justify-between | mb-12 lg:mb-8">
                          <div className="flex gap-2">
                            {links[1].titleIcon ? (
                              <div>
                                <Image
                                  src={links[1].titleIcon}
                                  alt=""
                                  width={25}
                                  height={25}
                                  priority={true}
                                />
                              </div>
                            ) : null}
                            <div className="font-heading text-lg xl:text-xl max-w-[155px] lg:max-w-[185px]">
                              {links[1].title}
                            </div>
                          </div>
                          <div>
                            <Image
                              src={links[1].icon}
                              alt=""
                              width={40}
                              height={40}
                              className="opacity-50 group-hover:opacity-100 group-hover:-rotate-45 transition-all ease-out"
                              priority={true}
                            />
                          </div>
                        </div>
                        <div className="max-w-[197px] lg:max-w-[250px]">
                          {links[1].description}
                        </div>
                      </div>
                      {links[1].image ? (
                        <Image
                          src={links[1].image}
                          alt=""
                          width={186}
                          height={158}
                          className="w-full mt-auto"
                          priority={true}
                        />
                      ) : null}
                      <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#C958BD] transition-all rounded-lg"></div>
                    </div>
                  </Link>
                ) : null}
              </div>
              <div className="lg:row-[2/3]">
                {links[2] ? (
                  <Link
                    key={links[2].slug}
                    href={links[2].slug}
                    target="_blank"
                  >
                    <div
                      className="rounded-lg relative group overflow-hidden h-full"
                      style={listThreeGradientStyle}
                    >
                      <div className="m-4 xl:m-7">
                        <div className="flex justify-between | mb-12 lg:mb-8">
                          <div className="flex gap-2">
                            {links[2].titleIcon ? (
                              <div>
                                <Image
                                  src={links[2].titleIcon}
                                  alt=""
                                  width={25}
                                  height={25}
                                  priority={true}
                                />
                              </div>
                            ) : null}
                            <div className="font-heading text-lg xl:text-xl max-w-[155px] lg:max-w-[185px]">
                              {links[2].title}
                            </div>
                          </div>
                          <div>
                            <Image
                              src={links[2].icon}
                              alt=""
                              width={40}
                              height={40}
                              className="opacity-50 group-hover:opacity-100 group-hover:-rotate-45 transition-all ease-out"
                              priority={true}
                            />
                          </div>
                        </div>
                        <div className="max-w-[270px]">
                          {links[2].description}
                        </div>
                      </div>
                      {links[2].image ? (
                        <Image
                          src={links[2].image}
                          alt=""
                          width={186}
                          height={158}
                          className="w-full mt-auto"
                          priority={true}
                        />
                      ) : null}
                      <div className="absolute inset-0 bg-transparent border border-transparent group-hover:border-[#29D184] transition-all rounded-lg"></div>
                    </div>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="basis-1/3 | bg-gradient-to-b from-[#0A0062] to-[#4016AB]">
          <div className="p-8 md:py-7 md:pl-8 xl:pt-14 xl:pl-16">
            <div className="font-medium | lg:max-w-col-3">
              <p className="flex items-center gap-1 | text-sm text-white font-semibold uppercase | border-[#ffffff66] border-b-[0.5px] border-spacing-4 pb-4 mb-8">
                <Image
                  src="/assets/cmp/navigation/icons/document-sketch.svg"
                  alt="icon"
                  width={15}
                  height={15}
                  priority={true}
                />
                Top content services
              </p>
              {features.map((feature, index) => (
                <div
                  key={feature.slug}
                  className={clsx(
                    index !== features.length - 1 ? 'mb-9' : 'mb-0'
                  )}
                >
                  <Link
                    href={feature.slug}
                    target="_blank"
                    className="block font-medium text-sm |  text-white focus:outline-none"
                  >
                    <div className="flex items-center gap-3 group">
                      <feature.icon className="group-hover:text-yellow-40" />
                      <div className="group-hover:text-yellow-40">
                        {feature.title}
                      </div>
                      <Arrow className="group-hover:text-yellow-40" />
                      {feature.isNew ? (
                        <span className="text-[10px] text-[#B78EF0] font-semibold border border-[#B78EF0] py-1 px-2 rounded-3xl">
                          New
                        </span>
                      ) : null}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
