import clsx from 'clsx'
import {
  Root,
  List,
  Item,
  Trigger,
  Content,
  Viewport
} from '@radix-ui/react-navigation-menu'

/* blocks */
import Link from '@/atoms/Link/Link'
import {
  PlatformDropdown,
  SolutionsDropdown,
  ResourcesDropdown,
  TalentNetworkDropdown
} from './dropdowns'

/* utils */
import { HeaderProps as Props } from './Header.utils'

import {
  dataLayerCtaClickEvent,
  dataLayerNavigationClickEvent
} from '@/utils/functions/dataLayerEvents'

/* assets */
import { ReactComponent as PepperLogo } from 'public/assets/icons/PepperLogo.svg'
import { ReactComponent as WhitePepperLogo } from 'public/assets/icons/WhitePepperLogo.svg'
import { ReactComponent as DownIcon } from 'public/assets/icons/down.svg'

/* styling */
import styles from './desktop.module.css'

function DesktopHeader({
  showNavLinks,
  className,
  primaryCta,
  secondaryCta,
  tertiaryCta,
  mode,
  platformData,
  talentNetworkData,
  solutionsData,
  resourcesData
}: Props): JSX.Element {
  const preventHover = (event: React.PointerEvent<HTMLElement>) => {
    event.preventDefault()
  }

  return (
    <nav
      className={clsx(
        className,
        mode === 'dark' ? 'bg-blue-web-90 text-white' : 'bg-white text-gray-80',
        'sticky top-0 left-0 | h-18 | z-50'
      )}
    >
      <div className="max-header-width | flex items-center justify-between | mx-auto px-6 | h-full">
        <div className="flex items-center">
          {showNavLinks ? (
            <Link
              href="/"
              className="z-[1] | pb-3"
              title="Homepage"
              onClick={() =>
                dataLayerNavigationClickEvent({ navigationMenuName: 'Logo' })
              }
            >
              {mode === 'dark' ? (
                <WhitePepperLogo style={{ width: 174, height: 33 }} />
              ) : (
                <PepperLogo style={{ width: 174, height: 33 }} />
              )}
            </Link>
          ) : mode === 'dark' ? (
            <WhitePepperLogo style={{ width: 174, height: 33 }} />
          ) : (
            <PepperLogo style={{ width: 174, height: 33 }} />
          )}

          <Root
            className={clsx(
              'flex justify-center | h-full | ml-10',
              !showNavLinks && '!hidden'
            )}
          >
            <List className="flex items-center gap-6">
              {/* Platform menu */}
              <Item>
                <Trigger
                  className="flex items-center | py-6"
                  onPointerMove={preventHover}
                  onPointerLeave={preventHover}
                >
                  Platform <DownIcon className="ml-1.5" />
                </Trigger>
                <Content
                  className={styles.navDropdownContent}
                  onPointerMove={preventHover}
                  onPointerLeave={preventHover}
                >
                  <PlatformDropdown {...platformData} />
                </Content>
              </Item>

              {/* Solutions menu */}
              <Item>
                <Trigger
                  className="flex items-center |  py-6"
                  aria-expanded="true"
                  data-state="open"
                  onPointerMove={preventHover}
                  onPointerLeave={preventHover}
                >
                  Solutions <DownIcon className="ml-1.5" />
                </Trigger>
                <Content
                  className={styles.navDropdownContent}
                  onPointerMove={preventHover}
                  onPointerLeave={preventHover}
                >
                  <SolutionsDropdown {...solutionsData} />
                </Content>
              </Item>

              {/* Resources menu */}
              <Item>
                <Trigger
                  className="flex items-center | py-6"
                  onPointerMove={preventHover}
                  onPointerLeave={preventHover}
                >
                  Resources <DownIcon className="ml-1.5" />
                </Trigger>
                <Content
                  className={styles.navDropdownContent}
                  onPointerMove={preventHover}
                  onPointerLeave={preventHover}
                >
                  <ResourcesDropdown {...resourcesData} />
                </Content>
              </Item>

              {/* Talent Network menu */}
              <Item>
                <Trigger
                  className="flex items-center | py-6"
                  onPointerMove={preventHover}
                  onPointerLeave={preventHover}
                >
                  Talent Network <DownIcon className="ml-1.5" />
                </Trigger>
                <Content
                  className={clsx(styles.navDropdownContent)}
                  onPointerMove={preventHover}
                  onPointerLeave={preventHover}
                >
                  <TalentNetworkDropdown {...talentNetworkData} />
                </Content>
              </Item>
            </List>

            <div className="absolute left-0 top-18 | w-screen">
              <Viewport
                className={clsx(styles.navViewport, 'rounded-b-xl shadow-md')}
              />
            </div>
          </Root>
        </div>

        <div className={clsx(!showNavLinks && 'hidden', 'flex items-center')}>
          {tertiaryCta ? (
            <Link
              href={tertiaryCta?.url}
              className={clsx(
                mode === 'dark' ? null : 'text-blue-50',
                'new-btn-neutral | max-h-8 | text-base font-medium | mr-3 px-4 py-1.5 underline underline-offset-2 | lg:hidden xl:inline-block'
              )}
              onClick={() =>
                dataLayerCtaClickEvent({
                  ctaName: tertiaryCta.title,
                  sectionName: 'Header'
                })
              }
            >
              {tertiaryCta.title}
            </Link>
          ) : null}

          {secondaryCta ? (
            <Link
              href={secondaryCta.url}
              className={clsx(
                mode === 'dark' ? null : 'bg-blue-20 text-blue-50',
                'new-btn-tertiary bg-yellow-60 text-blue-web-90 | max-h-8 | text-base font-medium | mr-3 px-4 py-1.5 | lg:hidden xl:inline-block'
              )}
              onClick={() =>
                dataLayerCtaClickEvent({
                  ctaName: secondaryCta.title,
                  sectionName: 'Header'
                })
              }
            >
              {secondaryCta.title}
            </Link>
          ) : null}
          {primaryCta ? (
            <Link
              href={primaryCta.url}
              className="new-btn-primary | max-h-8 | text-base font-medium | px-4 py-1.5"
              onClick={() =>
                dataLayerCtaClickEvent({
                  ctaName: primaryCta.title,
                  sectionName: 'Header'
                })
              }
            >
              {primaryCta.title}
            </Link>
          ) : null}
        </div>
      </div>
    </nav>
  )
}

export default DesktopHeader
