/* solutions assets */
import { ReactComponent as B2BSaasIcon } from 'public/assets/cmp/navigation/icons/b2b-saas-icon.svg'
import { ReactComponent as BfsiIcon } from 'public/assets/cmp/navigation/icons/bfsi-icon.svg'
import { ReactComponent as HealthcareIcon } from 'public/assets/cmp/navigation/icons/healthcare-icon.svg'
import { ReactComponent as FmcgIcon } from 'public/assets/cmp/navigation/icons/fmcg-icon.svg'
import { ReactComponent as ConsumerTechIcon } from 'public/assets/cmp/navigation/icons/consumer-tech-icon.svg'

/* resources assets */
import { ReactComponent as PepperAIIcon } from 'public/assets/cmp/navigation/icons/resources-pepper-ai-icon.svg'
import { ReactComponent as EbookIcon } from 'public/assets/cmp/navigation/icons/resources-ebook-icon.svg'
import { ReactComponent as EventsIcon } from 'public/assets/cmp/navigation/icons/resources-star-events-icon.svg'
import { ReactComponent as CreatorHubIcon } from 'public/assets/cmp/navigation/icons/resources-creator-hub-icon.svg'

/* talent marketplace assets */
import { ReactComponent as BlogWritingIcon } from 'public/assets/cmp/navigation/icons/blog-writing-icon.svg'
import { ReactComponent as VideoIcon } from 'public/assets/cmp/navigation/icons/video-prod-icon.svg'
import { ReactComponent as LocalisationIcon } from 'public/assets/cmp/navigation/icons/localisation-icon.svg'
import { ReactComponent as WhitepapersIcon } from 'public/assets/cmp/navigation/icons/whitepapers-icon.svg'
import { ReactComponent as ThoughtLeadershipIcon } from 'public/assets/cmp/navigation/icons/thought-leadership-icon.svg'
import { ReactComponent as SubtitleIcon } from 'public/assets/cmp/navigation/icons/subtitle-icon.svg'
import { ReactComponent as VoiceOverIcon } from 'public/assets/cmp/navigation/icons/voice-over-icon.svg'
// import { ReactComponent as InfographIcon } from 'public/assets/cmp/navigation/icons/infograph-icon.svg'

export interface HeaderProps {
  className?: string
  primaryCta?: { title: string; url: string }
  secondaryCta?: { title: string; url: string }
  tertiaryCta?: { title: string; url: string }
  showNavLinks?: boolean
  mode: 'dark' | 'light'
  platformData: PlatformDropdownProps
  solutionsData: SolutionsDropdownProps
  resourcesData: ResourceDropdownProps
  talentNetworkData: TalentNetworkDropdownProps
}

// data

export interface PlatformDropdownProps {
  platforms: {
    title: string
    description: string
    slug: string
    image: string
    colorOne: string
    colorTwo: string
    data: {
      title: string
      description: string
      titleIcon: string
      videoUrl: string
    }
  }[]
  features: {
    title: string
    slug: string
    isNew?: boolean
  }[]
}

export interface TalentNetworkDropdownProps {
  links: {
    title: string
    description: string
    icon: string
    slug: string
    titleIcon: string
    image: string
    colorOne: string
    colorTwo: string
  }[]
  features: {
    title: string
    slug: string
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string
      }
    >
    isNew?: boolean
  }[]
}

export interface SolutionsDropdownProps {
  useCases: {
    title: string
    description: string
    icon: string
    slug: string
    colorOne: string
    colorTwo: string
    imageDesk: string
    imageMob: string
  }[]
  industries: {
    title: string
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string
      }
    >
    isNew?: boolean
    slug: string
  }[]
}

export interface ResourceDropdownProps {
  library: {
    title: string
    description: string
    image: string
    slug: string
    external?: boolean
    colorOne: string
    colorTwo: string
    icon: string
  }[]
  resources: {
    title: string
    description: string
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string
      }
    >
    slug: string
    isNew?: boolean
  }[]
}

const CircularRightIconPath = '/assets/cmp/navigation/icons/circular-right.svg'

export const platformData = {
  platforms: [
    {
      title: 'Gen AI for Enterprises',
      description: 'Enterprise-grade, secure, and full-stack Gen AI',
      image: '/assets/cmp/navigation/images/platform-gen-ai-mob.png',
      slug: '/product/gen-ai/',
      colorOne: '#20147E',
      colorTwo: '#5E1BCC',
      data: {
        title: 'Gen AI for Enterprises',
        description:
          'Get plagiarism free, optimized content created using the world’s most powerful AI engine',
        titleIcon:
          '/assets/cmp/navigation/icons/platform-gen-data-title-icon.svg',
        videoUrl: '/assets/cmp/navigation/images/video-gen.mp4'
      }
    },
    {
      title: 'Organic Marketing Stack',
      description: 'Drive organic growth with a full-blown content SEO suite',
      image: '/assets/cmp/navigation/images/platform-oms-mob.png',
      slug: '/product/organic-marketing-stack/',
      colorOne: '#cc970033',
      colorTwo: '#FFd55E',
      data: {
        title: 'Organic Marketing Stack',
        description:
          'Accelerate your revenue organically with our full-blown SEO marketing stack',
        titleIcon:
          '/assets/cmp/navigation/icons/platform-oms-data-title-icon.svg',
        videoUrl: '/assets/cmp/navigation/images/video-oms.mp4'
      }
    },
    {
      title: 'Talent Marketplace',
      description:
        'Get content created at scale with our expert-led talent network',
      image: '/assets/cmp/navigation/images/platform-tm-mob.png',
      slug: '/product/talent-marketplace/',
      colorOne: '#17364B',
      colorTwo: '#29D184',
      data: {
        title: 'Talent Marketplace',
        description:
          'Scale your content creation by working with creators from our expert-led talent network',
        titleIcon:
          '/assets/cmp/navigation/icons/platform-tm-data-title-icon.svg',
        videoUrl: '/assets/cmp/navigation/images/video-tm.mp4'
      }
    }
  ],
  features: [
    {
      title: 'Content Idea lab',
      slug: '/features/content-idea-lab/'
    },
    {
      title: 'Content Editor',
      slug: '/features/content-editor/'
    },
    {
      title: 'Content Audit',
      slug: '/features/content-audit/'
    },
    {
      title: 'Content ROI and Analytics',
      slug: '/features/measure-content-roi/'
    },
    {
      title: 'Content Grader',
      slug: '/features/content-grader/'
    }
  ]
}

export const solutionsData = {
  useCases: [
    {
      title: 'SEO',
      description: 'Optimize your online presence with expert SEO strategies',
      slug: '/seo/',
      colorOne: '#36262B',
      colorTwo: '#EFC051',
      imageDesk: '/assets/cmp/navigation/images/use-case-seo-desk.png',
      imageMob: '/assets/cmp/navigation/images/use-case-seo-mob.png',
      icon: CircularRightIconPath
    },
    {
      title: 'Video',
      description: 'Elevate your brand value with engaging and creative videos',
      slug: '/video-services/',
      colorOne: '#3C1957',
      colorTwo: '#C958BD',
      imageDesk: '/assets/cmp/navigation/images/use-case-video-desk.png',
      imageMob: '/assets/cmp/navigation/images/use-case-video-mob.png',
      icon: CircularRightIconPath
    },
    {
      title: 'Demand Gen',
      description:
        'Boost market impact with targeted demand generation solutions',
      slug: '/demand-gen/',
      colorOne: '#17364B',
      colorTwo: '#29D184',
      imageDesk: '/assets/cmp/navigation/images/use-case-demand-gen-desk.png',
      imageMob: '/assets/cmp/navigation/images/use-case-demand-gen-mob.png',
      icon: CircularRightIconPath
    },
    {
      title: 'Translation',
      description: 'Bridge language barriers with expert translation services',
      slug: '/translation/',
      colorOne: '#735267',
      colorTwo: '#C46D51',
      imageDesk: '/assets/cmp/navigation/images/use-case-translation-desk.png',
      imageMob: '/assets/cmp/navigation/images/use-case-translation-mob.png',
      icon: CircularRightIconPath
    }
  ],
  industries: [
    {
      title: 'B2B SaaS',
      icon: B2BSaasIcon,
      slug: '/industries/b2b-saas/'
    },
    {
      title: 'BFSI',
      icon: BfsiIcon,
      slug: '/industries/bfsi/'
    },

    {
      title: 'Healthcare',
      icon: HealthcareIcon,
      slug: '/industries/healthcare/'
    },
    {
      title: 'FMCG',
      icon: FmcgIcon,
      slug: '/industries/fmcg-cpg/'
    },
    {
      title: 'Consumer Tech',
      icon: ConsumerTechIcon,
      slug: '/industries/consumer-tech/'
    }
  ]
}

export const resourcesData = {
  library: [
    {
      title: 'Blogs',
      description:
        'Learn about content marketing and technology to get ahead of the curve',
      image: '/assets/cmp/navigation/images/resources-blogs-mob.png',
      slug: '/blog/',
      colorOne: '#cc970033',
      colorTwo: '#FFD55E',
      icon: CircularRightIconPath
    },
    {
      title: 'Case studies',
      description:
        'Uncover how our customers drive revenue with Pepper Content',
      image: '/assets/cmp/navigation/images/resources-case-studies-mob.png',
      slug: '/case-study/',
      colorOne: '#4de59333',
      colorTwo: '#20DF78',
      icon: CircularRightIconPath
    },
    {
      title: 'Global Marketing Leaders',
      description:
        'Hear top global marketing leaders talk about content and marketing',
      image: '/assets/cmp/navigation/images/resources-gml-mob.png',
      slug: '/global-marketing-leaders/',
      colorOne: '#221979',
      colorTwo: '#7566FF',
      icon: CircularRightIconPath
    }
  ],
  resources: [
    {
      title: 'Help Center',
      description: `All helpful resources for Peppers business's platform.`,
      icon: PepperAIIcon,
      slug: 'https://sixth-crocus-23f.notion.site/Guides-by-Usecase-09add85d608f47908227f23c758c4afa?pvs=4'
    },
    {
      title: 'Ebooks and Whitepapers',
      description: 'Read about latest trends, insights related to marketing.',
      icon: EbookIcon,
      slug: '/resources/'
    },
    {
      title: 'Events',
      description: 'Join us for content marketing events, virtual and offline.',
      icon: EventsIcon,
      slug: '/events/'
    },
    {
      title: 'Creator Hub',
      description: 'Learn how to install, set up and use Pepper CMP.',
      icon: CreatorHubIcon,
      slug: 'https://peppercontent.freshdesk.com/support/home'
    },
    {
      title: 'Samples',
      description:
        'A collection of content writing and design sample projects by Pepper Content.',
      icon: WhitepapersIcon,
      slug: '/samples/'
    }
  ]
}

export const talentNetworkData = {
  links: [
    {
      title: 'On-demand experts for high-quality content',
      slug: '/product/talent-marketplace/',
      description: '',
      icon: CircularRightIconPath,
      titleIcon: '',
      image: '/assets/cmp/navigation/images/experts-talent-marketplace.png',
      colorOne: '#1C1563',
      colorTwo: '#493AD5'
    },
    {
      title: 'Top 3% Talent',
      slug: '/blog/editorial-excellence-how-we-find-the-best-writers-on-our-platform/',
      description: 'How we make sure you hire top 3% of freelance talent',
      icon: CircularRightIconPath,
      titleIcon: '/assets/cmp/navigation/icons/star-tm.svg',
      image: '',
      colorOne: '#3C1957',
      colorTwo: '#C958BD'
    },
    {
      title: 'Apply as a Creator',
      slug: '/product/creator/',
      description:
        'Join Pepper’s expert network and work with world-class brands',
      icon: CircularRightIconPath,
      titleIcon: '/assets/cmp/navigation/icons/brush-square-tm.svg',
      image: '',
      colorOne: '#17364B',
      colorTwo: '#29D184'
    }
  ],
  features: [
    {
      title: 'Blog Writing Services',
      slug: '/writing-services/blog-writing-services/',
      icon: BlogWritingIcon
    },
    {
      title: 'Video Production',
      slug: '/video-services/',
      icon: VideoIcon
    },
    {
      title: 'Localisation',
      slug: '/language-services/',
      icon: LocalisationIcon
    },
    {
      title: 'Whitepapers',
      slug: '/writing-services/white-paper-writing/',
      icon: EbookIcon
    },
    {
      title: 'Thought Leadership',
      slug: '/writing-services/thought-leadership-writing-services/',
      icon: ThoughtLeadershipIcon
    },
    {
      title: 'Subtitling',
      slug: '/language-services/subtitling/',
      icon: SubtitleIcon
    },
    {
      title: 'Voice Over',
      slug: '/video-services/voice-overs-and-dubbing/',
      icon: VoiceOverIcon
    }
    // {
    //   title: 'Infographic',
    //   slug: '/en/design-services/infographic/',
    //   icon: InfographIcon
    // }
  ]
}
