import { Fragment } from 'react'

/* blocks */
import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'
import {
  PlatformDropdownProps,
  SolutionsDropdownProps,
  ResourceDropdownProps,
  TalentNetworkDropdownProps
} from '@/cmp/molecules/Header/Header.utils'

interface Props {
  showNavLinks: boolean
  primaryCta?: { title: string; url: string }
  secondaryCta?: { title: string; url: string }
  tertiaryCta?: { title: string; url: string }
  mode: 'dark' | 'light'
  platformData: PlatformDropdownProps
  solutionsData: SolutionsDropdownProps
  resourcesData: ResourceDropdownProps
  talentNetworkData: TalentNetworkDropdownProps
}

function Header({
  showNavLinks,
  primaryCta = {
    title: 'Start a Free Trial',
    url: 'https://business.peppercontent.io/signup'
  },
  secondaryCta = { title: 'Book A Demo', url: '/book-a-demo/' },
  tertiaryCta = { title: 'Log in', url: '/login/' },
  platformData,
  solutionsData,
  resourcesData,
  talentNetworkData,
  mode
}: Props) {
  return (
    <Fragment>
      <MobileHeader
        className="lg:hidden"
        platformData={platformData}
        solutionsData={solutionsData}
        resourcesData={resourcesData}
        talentNetworkData={talentNetworkData}
        primaryCta={primaryCta}
        secondaryCta={secondaryCta}
        tertiaryCta={tertiaryCta}
        mode={mode}
      />
      <DesktopHeader
        className="hidden lg:block"
        primaryCta={primaryCta}
        secondaryCta={secondaryCta}
        tertiaryCta={tertiaryCta}
        showNavLinks={showNavLinks}
        platformData={platformData}
        solutionsData={solutionsData}
        resourcesData={resourcesData}
        talentNetworkData={talentNetworkData}
        mode={mode}
      />
    </Fragment>
  )
}

export default Header
